.contact-container {
    padding: 20px;
    background-color: #121212;
    color: white;
  }
  
  .map {
    margin-bottom: 20px;
  }
  
  .contact-info {
    padding: 20px;
    background-color: #1E1E1E;
    border-radius: 10px;
  }
  
  .contact-info h1, .contact-info h2 {
    margin-bottom: 20px;
  }
  
  .info-text {
    font-size: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    color: #FFC300;
  }
  
  .info-text span {
    color: grey;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .form input {
    height: 40px;
    border: 1px solid #666;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #1E1E1E;
    color: white;
  }
  
  .submit-button {
    background-color: #FFC300;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    color: #121212;
    font-weight: bold;
    align-self: center;
    width: 30%;
  }
  
  .social-links {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .social-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  
  .social-link p {
    margin-top: 5px;
    color: grey;
  }
  