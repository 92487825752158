.jummah-container {
  padding: 20px;
  background-color: #121212;
  color: #fff;
  text-align: center;
}

h1 {
  margin-bottom: 20px;
  font-size: 2rem;
}

.masjid {
  margin-bottom: 40px;
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
}

.masjid-name {
  margin-bottom: 20px;
}

.masjid-name h2 {
  margin: 0;
  font-size: 1.5rem;
}

.masjid-name p {
  margin: 5px 0;
}

.khutbah-details {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.khateebs-and-times {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.khateeb-time-pair {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
}

.khateeb {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.khateeb-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 5px;
}

.khateeb p {
  margin: 5px 0;
  font-size: 1rem;
}

.time {
  font-size: 1rem;
  margin-left: 20px;
}
