.programs-container {
    padding: 20px;
    background-color: #080e26;
    color: #8c98ca;
    text-align: center;
    min-height: 100vh; /* Ensure the container takes full height of the viewport */
  }
  
  h1 {
    margin-bottom: 20px;
    font-size: 3rem; /* Increase the font size of the heading */
    color: #8c98ca;
  }
  
  .programs-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
  
  .program-item {
    background-color: #080e26;
    border: 1px solid #8c98ca;
    border-radius: 10px;
    padding: 20px;
    width: 350px; /* Increase the width of the program item */
    text-align: center;
  }
  
  .program-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px; /* Increase margin to add more space below the image */
  }
  
  .program-item h2 {
    margin-top: 20px; /* Increase margin to add more space above the name */
    font-size: 2rem; /* Increase the font size of the program name */
    color: #8c98ca;
  }
  