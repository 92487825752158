/* Testimonials.css */
.testimonials-container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .testimonials-list {
    margin-top: 20px;
  }
  
  .testimonial-item {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial-body {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .testimonial-name {
    font-size: 14px;
    color: #777;
    text-align: right;
  }
  