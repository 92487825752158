.payment-form {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
}

.payment-form h2 {
  color: #FFC300;
  text-align: center;
  margin-bottom: 20px;
}

.payment-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.payment-form input[type="number"],
.payment-form input[type="text"],
.payment-form input[type="email"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.payment-form label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #333;
}

.payment-form input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.payment-form .StripeElement {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.payment-form button[type="submit"] {
  background-color: #FFC300;
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: bold;
}

.payment-form button[type="submit"]:hover {
  background-color: #e6b800;
}

.payment-form button[type="submit"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.payment-form button[onClick] {
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.payment-form button[onClick]:hover {
  background-color: #e9ecef;
}

@media (max-width: 768px) {
  .payment-form {
    padding: 20px;
  }
}

