.donation-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.donation-page h1 {
  color: #FFC300;
  text-align: center;
  margin-bottom: 30px;
}

.categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.category {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.category:hover {
  transform: translateY(-5px);
}

.category h2 {
  color: #333;
  margin-bottom: 10px;
}

.category p {
  color: #666;
  margin-bottom: 10px;
}

.category progress {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.category progress::-webkit-progress-bar {
  background-color: #e0e0e0;
  border-radius: 5px;
}

.category progress::-webkit-progress-value {
  background-color: #FFC300;
  border-radius: 5px;
}

.category button {
  background-color: #FFC300;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.category button:hover {
  background-color: #e6b800;
}

@media (max-width: 768px) {
  .categories {
    grid-template-columns: 1fr;
  }
}

.other-methods-button,
.manage-subscription-button {
  display: inline-block;
  background-color: transparent;
  color: #FFC300;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border: 2px solid #FFC300;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.other-methods-button:hover,
.manage-subscription-button:hover {
  color: #ffffff;
  background-color: #FFC300;
}

.donation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
