.about-container {
    display: flex;
    padding: 20px;
  }
  
  .about-sidebar {
    width: 20%;
    padding: 20px;
    border-right: 1px solid #ddd;
  }
  
  .about-sidebar h2 {
    margin-bottom: 20px;
  }
  
  .about-sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .about-sidebar ul li {
    margin-bottom: 10px;
  }
  
  .about-sidebar ul li a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
  }
  
  .about-content {
    width: 80%;
    padding: 20px;
  }
  
  .about-content h3 {
    margin-top: 0;
  }
  
  .about-content p {
    line-height: 1.6;
  }
  