/* FlipWords.css */
.flip-words-container {
    position: relative;
    display: inline-block;
    text-align: left;
    color: var(--text-color-neutral-900, #1a202c); /* Adjust the color based on your needs */
    padding-left: 0.5rem; /* Equivalent to px-2 in Tailwind */
    padding-right: 0.5rem; /* Equivalent to px-2 in Tailwind */
  }
  
  .flip-words-container.dark {
    color: var(--text-color-yellow-500, #ecc94b); /* Adjust the color based on your needs */
  }
  
  .flip-words-letter {
    display: inline-block;
    color: var(--text-color-yellow-500, #ecc94b); /* Adjust the color based on your needs */
  }
  