header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px 20px;
  position: relative;
}

.logo img {
  height: 50px;
}

nav {
  display: flex;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: center;
}

nav ul li {
  position: relative;
}

nav ul li a {
  text-decoration: none;
  color: #000;
  padding: 8px 12px;
  border-radius: 20px;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

nav ul li a:hover {
  background-color: #ffcc00;
  color: #000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.dropdown:hover .dropdown-menu,
.dropdown-menu:hover {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  min-width: 150px;
  border-radius: 10px;
  overflow: hidden;
}

.dropdown-menu li {
  padding: 0;
  border-bottom: 1px solid #ddd;
}

.dropdown-menu li a {
  color: #000;
  display: block;
  padding: 10px 15px;
  border-radius: 0;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.take-action button {
  background-color: #ffcc00;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.take-action button:hover {
  background-color: #e6b800;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.nav-toggle {
  display: none;
}

@media (max-width: 768px) {
  header {
    flex-wrap: wrap;
  }

  .nav-toggle {
    display: block;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .hamburger {
    display: block;
    width: 30px;
    height: 3px;
    background-color: #000;
    position: relative;
  }

  .hamburger::before,
  .hamburger::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    transition: all 0.3s ease;
  }

  .hamburger::before {
    top: -8px;
  }

  .hamburger::after {
    bottom: -8px;
  }

  nav {
    display: none;
    width: 100%;
    order: 3;
  }

  nav.open {
    display: block;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  nav ul li {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

  nav ul li a {
    display: block;
    padding: 10px;
    border-radius: 0;
    font-size: 1rem;
  }

  nav ul li a:hover {
    background-color: #ffcc00;
    box-shadow: none;
  }

  .dropdown-menu {
    position: static;
    display: none;
    box-shadow: none;
    width: 100%;
    border-radius: 0;
  }

  .dropdown-menu.open {
    display: block;
  }

  .take-action {
    order: 2;
  }
}
