.dawah-container {
    padding: 20px;
    background-color: #f8f9fa;
    color: #333;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
  }
  
  .intro-text {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .dawah-section {
    margin-bottom: 40px;
  }
  
  .dawah-definition {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  blockquote {
    font-size: 1.2rem;
    font-style: italic;
    color: #555;
    margin: 20px 0;
    padding-left: 20px;
    border-left: 4px solid #ddd;
  }
  
  .dawah-info p {
    margin-bottom: 20px;
  }
  
  .testimonials {
    margin-top: 40px;
  }
  
  .testimonials h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .testimonial {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  .actions {
    text-align: center;
    margin-top: 40px;
  }
  
  .highlight-link {
    color: red;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .donate-button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
  }
  