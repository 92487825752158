.blog-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.blog-posts {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
}

.blog-post {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-meta {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.blog-post h2 {
  color: #333;
  margin-bottom: 10px;
}

.blog-post p {
  line-height: 1.6;
  color: #444;
}

.blog-link {
  color: #0066cc;
  text-decoration: none;
  border-bottom: 1px solid #0066cc;
  padding-bottom: 1px;
  transition: all 0.2s ease;
}

.blog-link:hover {
  color: #004499;
  border-bottom: 2px solid #004499;
  background-color: rgba(0, 102, 204, 0.1);
}

.blog-link:active {
  color: #003366;
}

.blog-link:visited {
  color: #551A8B;
  border-bottom-color: #551A8B;
}
  