.events-container {
    padding: 20px;
    background-color: #f8f9fa;
    color: #333;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
  }
  
  .date-picker {
    display: block;
    margin: 0 auto 20px auto;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 250px;
    text-align: center;
  }
  
  .events-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .event-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .event-details {
    cursor: pointer;
  }
  
  .event-item h3 {
    margin: 0;
    font-size: 1.5rem;
  }
  
  .event-item p {
    margin: 5px 0 0 0;
    font-size: 1rem;
    color: #555;
  }
  
  .event-item button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: white;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .view-more-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #467FA2;
    color: white;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    text-align: center;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content img {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .modal-content p {
    font-size: 1rem;
    color: #555;
  }
  
  .modal-content button {
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: white;
    cursor: pointer;
    font-size: 1rem;
  }
  