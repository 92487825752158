/* Global styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Header styles */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px 20px;
}

.logo img {
  height: 50px;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0;
}

nav ul li a {
  text-decoration: none;
  color: #000;
}

.take-action button {
  background-color: #ffcc00;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

/* Footer styles */
footer {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
}

/* Main content styles */
main {
  padding: 20px;
}

.App {
  text-align: center;
}

/* Home component styles */
.home-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  background-color: #0a0e27; /* Dark blue background */
  color: #fff; /* White text color */
}

.home-content {
  max-width: 50%;
}

.home-title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.home-description {
  font-size: 1.25rem;
  margin-bottom: 30px;
}

.home-buttons {
  display: flex;
  gap: 20px;
}

.home-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.learn-more {
  background-color: #5a67d8; /* Purple color for Learn More button */
  color: #fff;
}

.learn-more:hover {
  background-color: #4c51bf; /* Darker purple on hover */
}

.get-involved {
  background-color: transparent;
  color: #5a67d8; /* Purple border and text for Get Involved button */
  border: 2px solid #5a67d8;
}

.get-involved:hover {
  background-color: #5a67d8;
  color: #fff; /* White text on hover */
}

.home-image {
  max-width: 50%;
  animation: slide-in 1s ease-out forwards; /* Apply slide-in animation */
  opacity: 0; /* Initial state for the animation */
}

@keyframes slide-in {
  from {
    transform: translateX(100%); /* Start off-screen to the right */
    opacity: 0;
  }
  to {
    transform: translateX(0); /* End in the original position */
    opacity: 1;
  }
}

/* Apply the animation to an element */
.element-with-slide-in {
  animation: slide-in 2s ease-out forwards; /* Adjust the duration here (e.g., 2s for 2 seconds) */
}


.home-image img {
  width: 100%;
  border-radius: 10px; /* Optional: rounded corners */
}

.prayer-times {
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
}

.prayer-times-title {
  margin-bottom: 10px;
  color: #5a67d8;
  transition: opacity 1s ease-in-out;
}

.prayer-time {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.prayer-time span {
  color: #fff;
}

.prayer-time-value {
  transition: opacity 1s ease-in-out;
}

.fade {
  opacity: 0;
}

/* Responsive styles for Home component */
@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
    padding: 20px;
  }

  .home-content {
    max-width: 100%;
    order: 2; /* Move content below the image on mobile */
  }

  .home-title {
    font-size: 2.5rem;
  }

  .home-description {
    font-size: 1rem;
  }

  .home-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .home-button {
    width: 100%;
  }

  .home-image {
    max-width: 100%;
    order: 1; /* Move image above the content on mobile */
    margin-bottom: 20px;
  }

  .prayer-times {
    margin-top: 20px;
  }

  .prayer-time {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }

  .prayer-time span:first-child {
    margin-bottom: 5px;
  }
}

/* Adjust the slide-in animation for mobile */
@media (max-width: 768px) {
  @keyframes slide-in {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .home-image {
    animation: slide-in 1s ease-out forwards;
  }
}

/* Additional responsive adjustments */
@media (max-width: 480px) {
  .home-title {
    font-size: 2rem;
  }

  .home-description {
    font-size: 0.9rem;
  }

  .prayer-times-title {
    font-size: 1.2rem;
  }

  .prayer-time span {
    font-size: 0.9rem;
  }
}
