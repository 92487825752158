/* Ramadan.css */
.ramadan-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.text-group {
  margin-bottom: 20px;
}

.arabic-text {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

.english-text {
  font-size: 18px;
  color: #555;
  text-align: center;
}

.video-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

video {
  border: none;
  border-radius: 10px;
}
